import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";

import "./components/styles/main.scss";
import GatewayInfo from "./components/GatewayInfo";
import PlaceOrder from "./components/PlaceOrder";
import LogDisplay from "./components/LogDisplay";
import Header from "./components/Header";
import { Auth0JwtPayload, DEFAULT_TIMEZONE, DisplayLogItem, Settings, Theme } from "./types";

const App = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [readAuthorized, setReadAuthorized] = useState<boolean>(false);
  const [writeAuthorized, setWriteAuthorized] = useState<boolean>(false);
  const [logs, setLogs] = useState<DisplayLogItem[]>([]);
  const [settings, setSettings] = useState<Settings>({
    theme: Theme.DEFAULT,
    orderDisplayDecimals: 3,
    timezone: DEFAULT_TIMEZONE,
  });

  const appendToLog = (item: DisplayLogItem): void => {
    setLogs((prevLog) => [...prevLog, item]);
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: "https://api.gateway.com",
          scope: "write:trades read:trades",
        });
        const decoded = jwtDecode<Auth0JwtPayload>(token);
        setReadAuthorized(decoded.permissions.indexOf("read:trades") !== -1);
        setWriteAuthorized(decoded.permissions.indexOf("write:trades") !== -1);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  return (
    <div className={settings.theme}>
      <div className="container">
        <div className="row">
          <Header settings={settings} setSettings={setSettings} />
        </div>
        {isAuthenticated && writeAuthorized && (
          <div className="row">
            <PlaceOrder log={appendToLog} />
          </div>
        )}
        {isAuthenticated && readAuthorized && (
          <div className="row">
            <GatewayInfo log={appendToLog} settings={settings} />
          </div>
        )}
      </div>
      <div className="footer">
        <LogDisplay logs={logs} />
      </div>
    </div>
  );
};

export default App;
