import { OptionTypeBase, StylesConfig } from "react-select";
import { JwtPayload } from "jwt-decode"

export const DEFAULT_TIMEZONE = "America/Los_Angeles";
export const GATEWAY_URL = "gateway.lunaristrading.co";

export enum Theme {
  DEFAULT = "theme-default",
  DARK = "theme-dark",
}

export type Settings = {
  theme: Theme;
  orderDisplayDecimals: number;
  timezone: string;
};

export enum OrderTableColumnType {
  CREATED = "Created",
  EXCHANGE = "Exchange",
  PAIR = "Pair",
  PRICE = "Price",
  FILLED_QUANTITY = "FilledQty",
  QUANTITY = "Quantity",
  PORTFOLIO = "Portfolio",
  ORDER_ID = "Order ID",
  CREATED_BY = "Created By",
  UPDATED = "Updated",
  STATE = "Status",
  CANCEL = "Cancel",
}

export enum DisplayLogItemType {
  INFO = "Info",
  ERROR = "Error",
  WARNING = "Warning",
}

export type DisplayLogItem = {
  type: DisplayLogItemType;
  message: string;
  sender?: string;
  timestamp: Date;
};

// Copied from exchange-gateway
export enum OrderType {
  MARKET = "MARKET",
  LIMIT = "LIMIT",
}

export enum OrderState {
  UNKNOWN = "UNKNOWN",
  FILLED = "FILLED",
  PARTIAL_FILLED = "PARTIAL_FILLED",
  CANCELLED = "CANCELLED",
  PAUSED = "PAUSED",
  ACTIVE = "ACTIVE",
}

export type DefiOrderOptions = {
  executionTime: number;
  maxQuantityIn?: number;
  minQuantityOut?: number;
  gasLimit: number;
};

export type OrderDetails = {
  type: OrderType;
  price?: number; // some market orders don't have price
  quantity?: number; // some market orders provide quantity as funding for quote instead of spot
  quoteQuantity?: number;
  portfolioName?: string;
  createdBy?: string;
  isIoc?: boolean;
  isPostOnly?: boolean;
};

export type Fill = {
  filledQty: number;
  totalQty: number;
};

export type OrderStatus = {
  state: OrderState;
  timestamp: number;
  fill?: Fill;
  comment?: string;
};

export type OrderResponse = {
  orderId: string;
  details: OrderDetails;
  currentStatus: OrderStatus;
  statusHistory: OrderStatus[];
};

export type ActiveOrdersResponse = {
  [exchangeName: string]: OrderResponse[];
};

export type PlaceOrderRequest = {
  exchangePairId: string;
  quantity?: number;
  quoteQuantity?: number;
  type: OrderType;
  price?: number;
  portfolioName?: string;
  createdBy?: string;
};

export interface DefiPlaceOrderRequest extends PlaceOrderRequest {
  quantity: number;
  maxQuantityIn?: number;
  minQuantityOut?: number;
  gasLimit: number;
  executionTime: number; // time from now to execute in (milliseconds)
}

export interface Auth0JwtPayload extends JwtPayload {
  permissions: string[];
} 

// Utilities copied from tob-gui for display

function getAbbrevInstrumentType(type: string) {
  switch (type) {
    case "SPOT":
      return "";
    case "FUTURE":
      return "F";
    case "FUTURE_PERP":
      return "F Perp";
    case "INVERSE_FUTURE":
      return "F-Inv";
    case "INVERSE_FUTURE_PERP":
      return "F-Inv Perp";
    case "MOVE_DAY":
      return "MOVE";
    case "MOVE_WEEK":
      return "MOVE-WK";
    case "MOVE_QUARTER":
      return "MOVE-Q";
  }
}

function getAbbrevInstrument(inst: string) {
  const split = inst.split(";");
  const underlying = split[0];
  const type = getAbbrevInstrumentType(split[1]);
  if (split.length > 2) {
    const exchange = split[3];
    if (split[2] === "NONE") {
      return [underlying, type, exchange].join(" ");
    }
    const expDate = new Date(split[2]);
    const expiration = [
      expDate.getFullYear().toString(),
      (expDate.getMonth() + 1).toString().padStart(2, "0"),
      expDate.getDate().toString(),
    ].join("-");
    return [underlying, type, expiration, exchange].join(" ");
  }
  return [underlying, type].join(" ");
}

export function getAbbrevExchangePair(
  exchangePair: string,
  showExchange: boolean = false
) {
  const [tradingPair, exchangeName] = exchangePair.split(">");
  const [inst1, inst2] = tradingPair.split("/");
  const abbrev =
    getAbbrevInstrument(inst1) + " / " + getAbbrevInstrument(inst2);
  return abbrev + (showExchange ? ` (${exchangeName})` : "");
}

export const selectStyle: StylesConfig<OptionTypeBase, false> = {
  control: (provided, state) => {
    return {
      ...provided,
      border: "none",
      boxShadow: "none",
      width: 150,
      height: 28,
      minHeight: 28,
    };
  },
  menu: (base) => ({
    ...base,
    marginTop: 2,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  option: (base) => ({
    ...base,
    padding: "4px 6px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: () => ({}),
};
