import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ReactModal from "react-modal";
import {
  Cell,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { matchSorter } from "match-sorter";
import { useAuth0 } from "@auth0/auth0-react";

import {
  ActiveOrdersResponse,
  DisplayLogItem,
  DisplayLogItemType,
  GATEWAY_URL,
  getAbbrevExchangePair,
  OrderResponse,
  OrderState,
  OrderStatus,
  OrderTableColumnType,
  Settings,
} from "../types";

type TableDisplayData = string | number | OrderResponse | null;

const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss.u";

const OrderTable = (props: {
  orders: ActiveOrdersResponse;
  settings: Settings;
  log: (item: DisplayLogItem) => void;
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const EMPTY_DATA_VAL = "-";

  const COLUMNS = [
    OrderTableColumnType.CREATED,
    OrderTableColumnType.EXCHANGE,
    OrderTableColumnType.PAIR,
    OrderTableColumnType.PRICE,
    OrderTableColumnType.FILLED_QUANTITY,
    OrderTableColumnType.QUANTITY,
    OrderTableColumnType.PORTFOLIO,
    OrderTableColumnType.ORDER_ID,
    OrderTableColumnType.CREATED_BY,
    OrderTableColumnType.UPDATED,
    OrderTableColumnType.STATE,
    OrderTableColumnType.CANCEL,
  ];

  const displayDecimals = props.settings.orderDisplayDecimals;
  const timezone = props.settings.timezone;

  const getDisplayFromOrder = (
    order: OrderResponse,
    columnType: OrderTableColumnType
  ): TableDisplayData => {
    const [orderId, exchangePairId] = order.orderId.split("<");
    switch (columnType) {
      case OrderTableColumnType.ORDER_ID:
        return orderId;
      case OrderTableColumnType.CREATED_BY:
        return order.details.createdBy || EMPTY_DATA_VAL;
      case OrderTableColumnType.PAIR:
        return getAbbrevExchangePair(exchangePairId);
      case OrderTableColumnType.FILLED_QUANTITY:
        if (!order.currentStatus.fill) return EMPTY_DATA_VAL;
        return order.currentStatus.fill.filledQty.toFixed(displayDecimals);
      case OrderTableColumnType.PRICE:
        if (!order.details.price) return EMPTY_DATA_VAL;
        return order.details.price.toFixed(displayDecimals);
      case OrderTableColumnType.QUANTITY:
        if (order.details.quantity)
          return order.details.quantity.toFixed(displayDecimals);
        if (order.details.quoteQuantity)
          return `${order.details.quoteQuantity.toFixed(
            displayDecimals
          )} funding`;
        return EMPTY_DATA_VAL;
      case OrderTableColumnType.PORTFOLIO:
        if (!order.details.portfolioName) return EMPTY_DATA_VAL;
        return order.details.portfolioName;
      case OrderTableColumnType.EXCHANGE:
        const [, exchangeName] = exchangePairId.split(">");
        return exchangeName;
      case OrderTableColumnType.STATE:
      case OrderTableColumnType.CANCEL:
        return order;
      case OrderTableColumnType.UPDATED:
        return DateTime.fromMillis(order.currentStatus.timestamp, {
          zone: timezone,
        }).toFormat(DATE_FORMAT);
      case OrderTableColumnType.CREATED:
        if (order.statusHistory.length === 0) return EMPTY_DATA_VAL;
        return DateTime.fromMillis(order.statusHistory[0].timestamp, {
          zone: timezone,
        }).toFormat(DATE_FORMAT);
      default:
        return EMPTY_DATA_VAL;
    }
  };

  const OrderStatusList = (props: {
    status: OrderStatus;
    settings: Settings;
  }) => {
    const timezone = props.settings.timezone;
    return (
      <ul>
        <li>state: {props.status.state}</li>
        <li>
          fill:{" "}
          {props.status.fill
            ? `${props.status.fill.filledQty} / ${props.status.fill.totalQty}`
            : EMPTY_DATA_VAL}
        </li>
        <li>
          comment:{" "}
          {props.status.comment ? props.status.comment : EMPTY_DATA_VAL}
        </li>
        <li>
          timestamp:{" "}
          {DateTime.fromMillis(props.status.timestamp, {
            zone: timezone,
          }).toFormat(DATE_FORMAT)}
        </li>
      </ul>
    );
  };

  const OrderStateDisplay = (props: {
    order: OrderResponse;
    settings: Settings;
  }) => {
    const [isDetailModalOpen, setDetailModalOpen] = useState(false);
    const [orderId, exchangePairId] = props.order.orderId.split("<");
    const state = props.order.currentStatus.state;

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <div className={`${state} state-indicator`}></div>
            <span onClick={() => setDetailModalOpen(true)}>{state}</span>
          </div>
        </div>
        <ReactModal
          isOpen={isDetailModalOpen}
          contentLabel="Order Details"
          className="settings-modal-content"
          overlayClassName="settings-modal-overlay"
          portalClassName={props.settings.theme}
        >
          <AiOutlineClose
            className="modal-close"
            onClick={() => setDetailModalOpen(false)}
          />
          <p className="header">
            {orderId} - {getAbbrevExchangePair(exchangePairId, true)}
          </p>
          <p className="header">Current Order Status</p>
          <OrderStatusList
            status={props.order.currentStatus}
            settings={props.settings}
          />
          <p className="header">Order Status History</p>
          {props.order.statusHistory.map((status) => (
            <OrderStatusList
              key={status.timestamp}
              status={status}
              settings={props.settings}
            />
          ))}
        </ReactModal>
      </div>
    );
  };

  const getAuth0AccessToken = async (): Promise<string> => {
    return await getAccessTokenSilently({
      audience: "https://api.gateway.com",
      scope: "write:trades",
    });
  };

  const cancelAllUserOrders = async (): Promise<void> => {
    const accessToken = await getAuth0AccessToken();
    const createdBy = user?.name;
    if (!createdBy) {
      props.log({
        message: "No logged in user to cancel orders for",
        sender: "exchange-gateway",
        type: DisplayLogItemType.WARNING,
        timestamp: new Date(),
      });
      return;
    }
    const response = await fetch(
      `https://${GATEWAY_URL}/api/orders?createdBy=${encodeURIComponent(
        createdBy
      )}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    ).then((res) => res.json());
    props.log({
      message: JSON.stringify(response),
      sender: "exchange-gateway",
      type:
        (response as { success: boolean }[]).filter((s) => s.success === false)
          .length === 0
          ? DisplayLogItemType.INFO
          : DisplayLogItemType.ERROR,
      timestamp: new Date(),
    });
  };

  const cancelAllOrders = async (): Promise<void> => {
    const accessToken = await getAuth0AccessToken();
    const response = await fetch(
      `https://${GATEWAY_URL}/api/orders?orderId=all`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    ).then((res) => res.json());
    props.log({
      message: JSON.stringify(response),
      sender: "exchange-gateway",
      type:
        (response as { success: boolean }[]).filter((s) => s.success === false)
          .length === 0
          ? DisplayLogItemType.INFO
          : DisplayLogItemType.ERROR,
      timestamp: new Date(),
    });
  };

  const Cancel = (props: {
    order: OrderResponse;
    log: (item: DisplayLogItem) => void;
  }) => {
    const cancelOrder = async (orderId: string) => {
      const accessToken = await getAuth0AccessToken();
      const [exchangeId, exchangePairId] = orderId.split("<");
      const [, exchangeName] = exchangePairId.split(">");
      const response = await fetch(
        `https://${GATEWAY_URL}/api/orders?orderId=${exchangeId}&exchange=${exchangeName}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      ).then((res) => res.json());
      props.log({
        message: JSON.stringify(response),
        sender: "exchange-gateway",
        type: response.success
          ? DisplayLogItemType.INFO
          : DisplayLogItemType.ERROR,
        timestamp: new Date(),
      });
    };

    return (
      <div>
        {props.order.currentStatus.state !== OrderState.CANCELLED &&
          props.order.currentStatus.state !== OrderState.FILLED && (
            <span
              className="cancel"
              onClick={() => cancelOrder(props.order.orderId)}
            >
              Cancel order
            </span>
          )}
      </div>
    );
  };

  const columns = useMemo(
    () =>
      COLUMNS.map((c) => {
        switch (c) {
          case OrderTableColumnType.STATE:
            return {
              Header: c,
              accessor: c,
              Cell: (cell: Cell) => (
                <OrderStateDisplay
                  order={cell.value}
                  settings={props.settings}
                />
              ),
            };
          case OrderTableColumnType.CANCEL:
            return {
              Header: c,
              accessor: c,
              Cell: (cell: Cell) => (
                <Cancel order={cell.value} log={props.log} />
              ),
            };
          default:
            return { Header: c, accessor: c };
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.settings]
  );

  const data = useMemo(
    () =>
      [...Object.values(props.orders)]
        .reduce((a, b) => a.concat(b), [])
        .map((order: OrderResponse) => {
          let d: { [column: string]: TableDisplayData } = {};
          columns.forEach((c) => {
            d[c.accessor] = getDisplayFromOrder(order, c.accessor);
          });
          return d;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.orders, columns]
  );

  const defaultSort = useMemo(() => [{ id: "CREATED", desc: true }], []);

  const globalFilter = (
    rows: Row<{ [column: string]: TableDisplayData }>[],
    columnIds: string[],
    filterValue: string
  ) => {
    return matchSorter(rows, filterValue, {
      threshold: matchSorter.rankings.CONTAINS,
      keys: columns.map((c) => `values.${c.accessor}`),
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetPage: false,
      globalFilter: globalFilter,
      initialState: { sortBy: defaultSort, pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 3 }}>
          <p className="header table-header">
            <b> Active Orders </b>
          </p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <input
              className="input"
              placeholder="Search..."
              onChange={(e) => {
                e.preventDefault();
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <span className="cancel" onClick={() => cancelAllUserOrders()}>
              {" "}
              Cancel User
            </span>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <span className="cancel" onClick={() => cancelAllOrders()}>
              {" "}
              Cancel ALL
            </span>
          </div>
        </div>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <span> {column.render("Header")}</span>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown className="sorted" />
                        ) : (
                          <FaSortUp className="sorted" />
                        )
                      ) : (
                        column.canSort && <HiOutlineDotsVertical />
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="pagination"
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingBottom: "50px",
        }}
      >
        <div className={`icon-small${canPreviousPage ? "" : " disabled"}`}>
          <FiChevronsLeft
            onClick={() => {
              if (canPreviousPage) gotoPage(0);
            }}
          />
        </div>
        <div className={`icon-small${canPreviousPage ? "" : " disabled"}`}>
          <FiChevronLeft
            onClick={() => {
              if (canPreviousPage) previousPage();
            }}
          />
        </div>
        <span>
          {" "}
          <input
            className="input-small"
            type="number"
            value={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = +e.target.value - 1;
              if (page < 0) {
                gotoPage(0);
              } else if (page > pageCount) {
                gotoPage(pageCount - 1);
              } else {
                gotoPage(page);
              }
            }}
            style={{ width: "30px" }}
          />
          {" of "} {pageOptions.length}{" "}
        </span>{" "}
        <div className={`icon-small${canNextPage ? "" : " disabled"}`}>
          <FiChevronRight
            onClick={() => {
              if (canNextPage) nextPage();
            }}
          />
        </div>
        <div className={`icon-small${canNextPage ? "" : " disabled"}`}>
          <FiChevronsRight
            onClick={() => {
              if (canNextPage) gotoPage(pageCount - 1);
            }}
          />
        </div>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default OrderTable;
