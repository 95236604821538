import { DateTime } from "luxon";
import { useState, ReactElement } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { DisplayLogItem } from "../types";

const LogDisplay = (props: { logs: DisplayLogItem[] }) => {
  const [logOpen, setLogOpen] = useState(false);

  const getComponentForDisplayLogItem = (
    item: DisplayLogItem
  ): ReactElement => {
    const timestamp = DateTime.fromJSDate(item.timestamp).toString();
    const className = item.type.toLowerCase();
    const prefix = item.sender
      ? `${item.sender} (${timestamp}): `
      : `(${timestamp}):`;
    return (
      <div key={timestamp}>
        <span className={`log-item ${className}`}>
          <span className="log-prefix">{prefix}</span>
          {item.message}
        </span>
      </div>
    );
  };

  return (
    <div className="log-base">
      <div className="log-divider"> </div>
      <div className="log-header" style={{ display: "flex" }}>
        <div style={{ flex: 1 }} className="header">
          Log
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div onClick={() => setLogOpen(!logOpen)}>
            {" "}
            <div className="icon">
              {logOpen ? <FiChevronDown /> : <FiChevronUp />}{" "}
            </div>
          </div>
        </div>
      </div>
      {logOpen && (
        <div className="log">
          {props.logs.map((l) => getComponentForDisplayLogItem(l))}
        </div>
      )}
    </div>
  );
};

export default LogDisplay;
