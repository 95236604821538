import { ReactElement, useEffect, useState } from "react";
import Select from "react-select";
import { FiSend } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";

import {
  DefiPlaceOrderRequest,
  DefiOrderOptions,
  DisplayLogItem,
  DisplayLogItemType,
  GATEWAY_URL,
  getAbbrevExchangePair,
  OrderType,
  PlaceOrderRequest,
  selectStyle,
} from "../types";

const CEFI_EXCHANGE_NAMES: string[] = [
  "Binance",
  "Bitfinex",
  "Coinbase",
  "Ftx",
  "Huobi",
  "Okex",
];
const DEFI_EXCHANGE_NAMES: string[] = ["UniswapV2", "Sushiswap"];
const EXCHANGES = [...CEFI_EXCHANGE_NAMES, ...DEFI_EXCHANGE_NAMES];

const PlaceOrder = (props: { log: (item: DisplayLogItem) => void }) => {
  const [orderType, setOrderType] = useState<OrderType>(OrderType.LIMIT);
  const [exchange, setExchange] = useState<string>();
  const [price, setPrice] = useState<number>(0.0);
  const [quantity, setQuantity] = useState<number>(0.0);
  const [pair, setPair] = useState<string>();
  const [exchangeToExchangePairIds, setExchangeToExchangePairIds] = useState<
    Map<string, string[]>
  >(new Map());
  const [portfolioName, setPortfolioName] = useState<string>("");
  const [defiOptions, setDefiOptions] = useState<DefiOrderOptions>({
    maxQuantityIn: 0,
    minQuantityOut: 0,
    gasLimit: 0,
    executionTime: 0,
  });

  const { getAccessTokenSilently, user } = useAuth0();

  const getAuth0AccessToken = async (scope: string): Promise<string> => {
    return await getAccessTokenSilently({
      audience: "https://api.gateway.com",
      scope: scope,
    });
  };

  const updateSupportedExchangePairIds = async (): Promise<void> => {
    const accessToken = await getAuth0AccessToken("write:trades");
    const res = await fetch(
      `https://${GATEWAY_URL}/api/supportedExchangePairs`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    const pairs = await res.json();
    setExchangeToExchangePairIds(new Map(Object.entries(pairs)));
  };

  const placeOrder = async (): Promise<void> => {
    const createdBy = user?.name;
    if (!pair || !quantity) {
      props.log({
        message:
          "Error placing order to exchange-gateway server. Are pair and quantity defined?",
        sender: "client",
        type: DisplayLogItemType.ERROR,
        timestamp: new Date(),
      });
      return;
    }
    let placeOrderRequest: PlaceOrderRequest = {
      exchangePairId: pair,
      type: orderType,
      price: price,
      portfolioName: portfolioName,
      createdBy: createdBy,
    };
    if (isQuoteQuantity())
      placeOrderRequest = { ...placeOrderRequest, quoteQuantity: quantity };
    else placeOrderRequest = { ...placeOrderRequest, quantity: quantity };

    if (isDefiOrder()) {
      if (orderType === OrderType.LIMIT) {
        props.log({
          message:
            "Error placing order to exchange-gateway server. Cannot place limit order on defi exchange",
          sender: "client",
          type: DisplayLogItemType.ERROR,
          timestamp: new Date(),
        });
        return;
      }
      placeOrderRequest = {
        ...placeOrderRequest,
        minQuantityOut: defiOptions.minQuantityOut,
        maxQuantityIn: defiOptions.maxQuantityIn,
        gasLimit: defiOptions.gasLimit,
        executionTime: defiOptions.executionTime,
      } as DefiPlaceOrderRequest;
    }

    const accessToken = await getAuth0AccessToken("write:trades");
    const response = await fetch(`https://${GATEWAY_URL}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(placeOrderRequest),
    }).then((res) => res.json());
    props.log({
      message: JSON.stringify(response),
      sender: "exchange-gateway",
      type: response.success
        ? DisplayLogItemType.INFO
        : DisplayLogItemType.ERROR,
      timestamp: new Date(),
    });
  };

  useEffect(() => {
    updateSupportedExchangePairIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExchangePairIdSelectOptions = (): {
    label: string;
    value: string;
  }[] => {
    if (!exchange) return [];
    const exchangeIds = exchangeToExchangePairIds.get(exchange);
    if (!exchangeIds) return [];
    return exchangeIds.map((id) => ({
      label: getAbbrevExchangePair(id),
      value: id,
    }));
  };

  const setExchangeDisplay = (name: string): void => {
    setExchange(name);
    setPair(undefined);
  };

  const setOrderTypeDisplay = (type: OrderType): void => {
    setOrderType(type);
    setPrice(0.0);
  };

  const isQuoteQuantity = (): boolean => {
    const isHuobiMarket =
      exchange === "Huobi" &&
      orderType === OrderType.MARKET &&
      quantity !== undefined &&
      quantity > 0;
    const pairElements = pair?.split(";");
    let pairType;
    if (pairElements && pairElements.length >= 2) pairType = pairElements[1];
    const isBinanceInvFuturesMarket =
      exchange === "Binance" &&
      orderType === OrderType.MARKET &&
      quantity !== undefined &&
      quantity !== 0 &&
      pairType !== undefined &&
      pairType.includes("INVERSE");
    return isHuobiMarket || isBinanceInvFuturesMarket;
  };

  const isDefiOrder = (): boolean => {
    return exchange !== undefined && DEFI_EXCHANGE_NAMES.includes(exchange);
  };

  const DefiOrderControls = (): ReactElement => {
    return (
      <div
        style={{ flex: 1, display: "flex", alignItems: "center" }}
        className="controls"
      >
        {quantity < 0 ? (
          <div style={{ flex: 1 }}>
            <form>
              <input
                className="input"
                name="min quantity out"
                type="number"
                step="0.1"
                value={defiOptions.minQuantityOut}
                onChange={(e) => {
                  e.preventDefault();
                  if (+e.target.value >= 0)
                    setDefiOptions({
                      ...defiOptions,
                      minQuantityOut: +e.target.value,
                    });
                }}
              />
            </form>
          </div>
        ) : (
          <div style={{ flex: 1 }}>
            <form>
              <label>Max Quantity In</label>
              <input
                className="input"
                name="max quantity in"
                type="number"
                step="0.1"
                value={defiOptions.maxQuantityIn}
                onChange={(e) => {
                  e.preventDefault();
                  if (+e.target.value >= 0)
                    setDefiOptions({
                      ...defiOptions,
                      maxQuantityIn: +e.target.value,
                    });
                }}
              />
            </form>
          </div>
        )}

        <div style={{ flex: 1 }}>
          <form>
            <label>Execution Time (ms)</label>
            <input
              className="input"
              name="execution time"
              type="number"
              step="1"
              value={defiOptions.executionTime}
              onChange={(e) => {
                e.preventDefault();
                if (+e.target.value >= 0)
                  setDefiOptions({
                    ...defiOptions,
                    executionTime: +e.target.value,
                  });
              }}
            />
          </form>
        </div>

        <div style={{ flex: 1 }}>
          <form>
            <label>Gas Limit</label>
            <input
              className="input"
              name="gas limit"
              type="number"
              step="1"
              value={defiOptions.gasLimit}
              onChange={(e) => {
                e.preventDefault();
                if (+e.target.value >= 0)
                  setDefiOptions({ ...defiOptions, gasLimit: +e.target.value });
              }}
            />
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="border" style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ flex: 1, display: "flex", alignItems: "center" }}
          className="controls"
        >
          <div style={{ flex: 1 }}>
            <form>
              <label>Exchange</label>
              <Select
                className="select"
                classNamePrefix="select"
                styles={selectStyle}
                placeholder="Select exchange..."
                components={{ IndicatorSeparator: () => null }}
                options={EXCHANGES.map((e) => ({
                  label: e,
                  value: e,
                }))}
                onChange={(e) => e && setExchangeDisplay(e.value)}
              />
            </form>
          </div>
          <div style={{ flex: 0.1 }} className="divider"></div>
          <div style={{ flex: 1 }}>
            <form>
              <label>Pair</label>
              <Select
                className="select"
                classNamePrefix="select"
                styles={selectStyle}
                placeholder="Select pair..."
                components={{ IndicatorSeparator: () => null }}
                options={getExchangePairIdSelectOptions()}
                onChange={(e) => e && setPair(e.value)}
              />
            </form>
          </div>
          <div style={{ flex: 1 }}>
            <form>
              <label>Order Type</label>
              <Select
                className="select"
                classNamePrefix="select"
                styles={selectStyle}
                placeholder="Select order type..."
                components={{ IndicatorSeparator: () => null }}
                options={Object.values(OrderType).map((type) => ({
                  label: type,
                  value: type,
                }))}
                onChange={(e) => e && setOrderTypeDisplay(e.value as OrderType)}
              />
            </form>
          </div>
          <div style={{ flex: 1 }}>
            <form>
              <div>Portfolio</div>
              <input
                className="input"
                name="portfolioName"
                value={portfolioName}
                onChange={(e) => {
                  e.preventDefault();
                  setPortfolioName(e.target.value);
                }}
              />
            </form>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ flex: 1 }}>
              <label>{isQuoteQuantity() ? "Quote Quantity" : "Quantity"}</label>
            </div>
            <div style={{ flex: 1 }}>
              <input
                className="input"
                name="quantity"
                type="number"
                step="0.1"
                value={quantity}
                onChange={(e) => {
                  e.preventDefault();
                  setQuantity(+e.target.value);
                }}
              />
            </div>
          </div>
          {orderType !== OrderType.MARKET && (
            <div style={{ flex: 1, flexDirection: "column" }}>
              <div style={{ flex: 1 }}>
                <label>Price</label>
              </div>
              <div style={{ flex: 1 }}>
                <input
                  className="input"
                  name="price"
                  type="number"
                  step="0.01"
                  value={price}
                  onChange={(e) => {
                    e.preventDefault();
                    if (+e.target.value >= 0) setPrice(+e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <button className="order-button" onClick={placeOrder}>
          <FiSend />
        </button>
      </div>
      {isDefiOrder() && (
        <div className="defi">
          {" "}
          <p className="header table-header"> Defi Order Controls </p>
          <div
            className="border"
            style={{
              display: "flex",
              alignItems: "center",
              width: "min-content",
            }}
          >
            <DefiOrderControls />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaceOrder;
