import { Settings as LuxonSettings } from "luxon";
import moment from "moment-timezone";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import ReactModal from "react-modal";
import Select from "react-select";

import { DEFAULT_TIMEZONE, selectStyle, Settings, Theme } from "../types";
import LoginButton from "./LoginButton";

const Header = (props: {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}) => {
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

  const getDefaultTimeZoneLabel = () => {
    let zoneName = LuxonSettings.defaultZoneName;
    if (!zoneName || zoneName === "") {
      zoneName = DEFAULT_TIMEZONE;
      LuxonSettings.defaultZoneName = zoneName;
    }
    return {
      label: zoneName,
      value: zoneName,
    };
  };

  const ThemeChangeRadioButtons = (props: {
    settings: Settings;
    setSettings: (settings: Settings) => void;
  }) => {
    const Buttons = (props: { settings: Settings }) => {
      const getReadableKey = (k: Theme): string => {
        return k === Theme.DARK ? "Dark mode" : "Default";
      };
      return (
        <div>
          {Object.values(Theme).map((k) => (
            <div key={k}>
              {props.settings.theme === k ? (
                <input type="radio" value={k} name={k} defaultChecked />
              ) : (
                <input type="radio" value={k} name={k} />
              )}{" "}
              <label>{getReadableKey(k)}</label>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div
        onChange={(e) => {
          props.setSettings({
            ...props.settings,
            theme: (e.target as HTMLInputElement).value as Theme,
          });
        }}
      >
        <Buttons settings={props.settings} />
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <div>
          <b className="site-header">Lunaris Trading</b>
        </div>
        <div>
          <span className="site-header">exchange-gateway</span>
        </div>
      </div>
      <div
        className="settings"
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "6px",
        }}
      >
        <div>
          <LoginButton />
        </div>
        <div className="icon">
          <FiSettings onClick={() => setSettingsModalOpen(true)} />
          <ReactModal
            isOpen={isSettingsModalOpen}
            className="settings-modal-content"
            overlayClassName="settings-modal-overlay"
            portalClassName={props.settings.theme}
          >
            <AiOutlineClose
              className="modal-close"
              onClick={() => setSettingsModalOpen(false)}
            />
            <p className="header">Settings</p>
            <p>Order Display Num Decimals</p>
            <input
              className="input"
              value={props.settings.orderDisplayDecimals}
              onChange={(e) => {
                e.preventDefault();
                const numDecimals = +e.target.value;
                if (
                  !isNaN(numDecimals) &&
                  0 <= numDecimals &&
                  numDecimals <= 20
                ) {
                  props.setSettings({
                    ...props.settings,
                    orderDisplayDecimals: numDecimals,
                  });
                }
              }}
            />
            <br />
            <p> Color Theme </p>
            <ThemeChangeRadioButtons
              settings={props.settings}
              setSettings={props.setSettings}
            />
            <p> Display timezone </p>
            <Select
              className="select"
              classNamePrefix="select"
              styles={selectStyle}
              placeholder="Timezone..."
              defaultValue={getDefaultTimeZoneLabel()}
              components={{ IndicatorSeparator: () => null }}
              options={moment.tz.names().map((zone) => ({
                label: zone,
                value: zone,
              }))}
              onChange={(e) => {
                if (e) {
                  props.setSettings({
                    ...props.settings,
                    timezone: e.value,
                  });
                }
              }}
            />
          </ReactModal>
        </div>
      </div>
    </div>
  );
};

export default Header;
