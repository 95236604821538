import { useEffect, useState } from "react";

import {
  ActiveOrdersResponse,
  DisplayLogItem,
  DisplayLogItemType,
  GATEWAY_URL,
  Settings,
} from "../types";
import OrderTable from "./OrderTable";

const GatewayInfo = (props: {
  log: (item: DisplayLogItem) => void;
  settings: Settings;
}) => {
  const [activeOrders, setActiveOrders] = useState<ActiveOrdersResponse>({});

  const processOrderUpdateMessage = (
    orderUpdateMessage: ActiveOrdersResponse
  ): void => {
    setActiveOrders(orderUpdateMessage);
  };

  const openOrderUpdateStream = async (): Promise<void> => {
    const ws = new WebSocket(`wss://${GATEWAY_URL}/ws/orderUpdates`);
    ws.onopen = () => {
      props.log({
        message: "Opened websocket connection to exchange-gateway server.",
        sender: "client",
        type: DisplayLogItemType.INFO,
        timestamp: new Date(),
      });
    };
    ws.onmessage = (ev: MessageEvent) => {
      processOrderUpdateMessage(JSON.parse(ev.data) as ActiveOrdersResponse);
    };
    ws.onclose = () => {
      props.log({
        message: "Websocket connection to exchange-gateway closed.",
        sender: "client",
        type: DisplayLogItemType.ERROR,
        timestamp: new Date(),
      });
    };
  };

  useEffect(() => {
    openOrderUpdateStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderTable
      orders={activeOrders}
      settings={props.settings}
      log={props.log}
    />
  );
};

export default GatewayInfo;
